<script setup lang="ts">
import { Tippy } from 'vue-tippy'
import type {
  HomeActionType,
  HomeActionItem,
  NovaBoxHomeActionProps,
} from './NovaBoxHomeAction.types'
import { BlockType } from '@composables/useBlockStatus'
import { LayerType } from '@store'
const layoutStore = useLayoutStore()
const { gtEvent } = useGoogleTag()

const props = defineProps<NovaBoxHomeActionProps>()

const userStore = useUserStore()
const { showRemoveFollowerModal } = useFollow()
const tippyRef = ref<typeof Tippy | null>(null)
const { t, locale, messages } = useI18n()
const homeActions = computed(
  () =>
    Object.keys(messages.value[locale.value].homeActions)
      .map((key) => ({
        id: key,
        name: t(`homeActions.${key}`),
        action: getAction[key as HomeActionType],
      }))
      .filter((item) =>
        filterAction(item.id as HomeActionType)
      ) as HomeActionItem[]
)

const onShowMobileAction = () => {}

const filterAction = (actionType: HomeActionType) => {
  const { followerAt, blockingAt } = props.source

  // 로그인 상태에서만 노출되는 액션
  const signInAllowActions: HomeActionType[] = ['blocking', 'removeFollower']

  // 비로그인 상태이고 로그인이 필요한 액션이 필터에 걸리면 비노출
  if (!userStore.isSignIn && signInAllowActions.includes(actionType))
    return false

  // 홈 사용자가 현재 사용자를 팔로우 하고 있지 않으면 팔로워 삭제하기 액션 비노출
  if (
    (blockingAt === 'Y' || followerAt === 'N') &&
    actionType === 'removeFollower'
  )
    return false

  // 홈 사용자를 현재 사용자가 차단 하고 있으면 차단하기 액션 비노출
  if (blockingAt === 'Y' && actionType === 'blocking') return false

  // 위 필터 조건을 충족하지 않는 액션은 노출
  return true
}

const getAction: { [Property in HomeActionType]: () => void } = {
  // 공유: NovaBoxHomeActionPanel.vue 내부에서 슬라이드 처리함
  share: () => {},
  // 차단
  blocking: () => {
    const params = {
      target: props.source.target,
      type: BlockType.BLOCKING,
      userSn: props.source.userSn,
      userNcnm: props.source.userNcnm,
      isDonation: props.source.isDonation,
    }
    callBlockDialog(params)
  },
  // 팔로워 삭제
  removeFollower: () =>
    showRemoveFollowerModal(props.source.userSn, props.source.target),
}
const openAccountSetting = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '마이 홈 > 프로필 수정 버튼 클릭',
    eventLabel: 'more-vertical',
    eventSlot: '프로필 수정 열기',
    eventI18nAddr: '',
    eventComponent: 'Button',
  })
  layoutStore['layer:open'](LayerType.USER_PROFILE_EDIT_PANEL)
}
const signOut = async () => {
  if (tippyRef.value) {
    await new Promise((resolve) => {
      tippyRef.value?.hide()
      setTimeout(resolve, 300)
    })
  }
  userStore.signOut()
}
</script>

<template>
  <Tippy
    ref="tippyRef"
    :append-to="'parent'"
    :interactive="true"
    :placement="'bottom-end'"
    :theme="'popover'"
    :trigger="'click'"
    @click.stop="onShowMobileAction"
  >
    <NovaButtonIcon
      v-if="isEqualUser"
      :icon="{ type: 'outline', name: 'settings' }"
      :size="22"
    />
    <NovaBoxHomeActionButton v-else />
    <template #content>
      <div v-if="isEqualUser" class="header-floating-button-box">
        <div class="profile-panal-opend">
          <button
            class="profile-buttton"
            type="button"
            @click="openAccountSetting"
          >
            <span class="label">{{ t('account') }}</span>
            <NovaIcon
              :icon="{ type: 'outline', name: 'chev-compact-right' }"
              class="chev"
            />
          </button>
          <button class="profile-buttton" type="button" @click="signOut()">
            <span class="label">{{ t('sign.out') }}</span>
            <NovaIcon
              :icon="{ type: 'outline', name: 'chev-compact-right' }"
              class="chev"
            />
          </button>
        </div>
      </div>

      <NovaBoxHomeActionPanel
        v-else
        :user-type="source.target"
        :home-actions="homeActions"
      />
    </template>
  </Tippy>
</template>
<style lang="scss" scoped>
:deep(.btn-icon) {
  .icon-wrap {
    .icon {
      color: $color-black;
    }
  }
}
.header-floating-button-box {
  position: relative;
  .profile-panal-opend {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 200px !important;
    gap: 10px;
    padding: 10px 18px 10px 20px;
    border-radius: 5px;
    box-shadow: 0px 2px 10px 0px #00000014;
    background-color: $color-bg-3;
    .profile-buttton {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      gap: 20px;
      height: 30px;
      .label {
        @include text-style($text-body-14-medium);
        color: $color-text-12;
      }
      .chev {
        flex-shrink: 0;
        color: $color-text-3;
      }
    }
  }
}
</style>
