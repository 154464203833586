<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import type {
  NovaBoxHomeActionPanelEmits,
  NovaBoxHomeActionPanelProps,
} from './NovaBoxHomeActionPanel.types'
import type {
  HomeActionItem,
  HomeActionType,
} from '@components/NovaBoxHomeAction/NovaBoxHomeAction.types'

const emit = defineEmits<NovaBoxHomeActionPanelEmits>()
const props = defineProps<NovaBoxHomeActionPanelProps>()

const swiper = ref<typeof Swiper | null>(null)
watch(
  () => props.homeActions,
  () => {
    if (swiper.value) {
      // swiperKey.value = uuidv4()
      swiper.value.slideReset()
    }
  }
)

// init swiper
const handleOnSetSwiper = (evt: typeof Swiper) => {
  swiper.value = evt
}

// 스와이프
const handleOnSlideTo = (slideIdx: number) => {
  swiper.value?.slideTo(slideIdx)
}

// 액션 이후 Tippy 닫기
const handleOnClose = () => {
  swiper.value?.slideTo(0)
  emit('close')
}

// 액션
const handleOnAction = (
  id: HomeActionType,
  action: HomeActionItem['action']
) => {
  if (id === 'share') {
    handleOnSlideTo(1)
    return
  }

  action()
  handleOnClose()
}
</script>

<template>
  <NovaBoxBase class="action-panel" :floating="true" :round="5">
    <Swiper
      class="swiper"
      :allow-touch-move="false"
      :auto-height="true"
      @swiper="handleOnSetSwiper"
    >
      <SwiperSlide>
        <button
          v-for="item in homeActions"
          :key="item.id"
          type="button"
          class="action-item"
          @click.stop="handleOnAction(item.id, item.action)"
        >
          <span class="label">{{ item.name }}</span>
          <NovaIcon
            :icon="{ type: 'outline', name: 'chev-compact-right' }"
            class="chev"
          />
        </button>
      </SwiperSlide>

      <SwiperSlide>
        <NovaBoxHomeActionSharePanel
          :user-type="userType"
          @go-back="handleOnSlideTo(0)"
          @close="handleOnClose"
        />
      </SwiperSlide>
    </Swiper>
  </NovaBoxBase>
</template>

<style lang="scss" scoped>
.action-panel {
  width: 200px !important;
  padding: 5px 0 !important;

  .swiper {
    width: 100%;
  }

  .action-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;
    height: 30px;
    padding: 0 15px 0 20px;
    margin: 5px 0;
    > .label {
      flex-grow: 1;
      display: flex;
      align-items: center;
      @include text-style($text-body-14-regular);
      line-height: 18px;
      color: $color-text-2;
    }

    > .chev {
      flex-shrink: 0;
      color: $color-text-3;
    }
  }
}
</style>
